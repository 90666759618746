import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import Cookies from "js-cookie";

import DropListAutocomplete from "../../../components/DropListAutocomplete"
import "./AdditionalInfo.scss"
import Loader from "../../../components/Loader"
import { Institution, User } from "../../../api";
import { toast } from "react-toastify";

// const schools = schoolsData.map((s) => s.name).sort()

const levels = [
  "100 Level",
  "200 Level",
  "300 Level",
  "400 Level",
  "500 Level",
  "600 Level",
  "700 Level",
]

const AdditionalInfo = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [schools, setSchools] = useState();
  const [departments, setDepartments] = useState();
  const [form, setForm] = useState({email: "", phone: "", school: "", department: "", level: 100 });

  useEffect(() => {
    window.scrollTo(0,0);
    if (!Cookies.get("_akad__usr__tk_") || Cookies.get("_akad__usr__tk_").length < 1) {
      return navigate("/signup");
    }

    User.getProfile({userID: Cookies.get("_akada__user_")}).then(res => {
      if (res.type && res.type === "error") {
        toast.error(res.message);
        return navigate("/");
      }
      setForm({...form, ...res.profile});
      console.log(res.profile);
    }).catch(err => {
      const error = err.response?.data || {message: "An error occured, check your network and retry."};
      toast.error(error.message);
    })

    Institution.getAll().then(res => {
      setSchools(res.schools);
      getSchoolDepartments(res.schools[0].id);
    }).catch(err => {
      const error = err.response?.data || {message: "An error occured, check your network and retry."};
      toast.error(error.message);
    })


  }, [])

  const getSchoolDepartments =(school_id) => {
    Institution.getDepartments({school_id}).then(res => {
      setDepartments(res.departments);
    }).catch(err => {
      const error = err.response?.data || {message: "An error occured, check your network and retry."};
      toast.error(error.message);
    })
  }

  const inputChange = e => {
    e.preventDefault();
    setForm({...form, [e.target.name]: e.target.value});
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = { 
      ...form,
      school: schools.find(sch =>sch.name === form["school"])?.id,
      emailType: form.studentEmail ? "personal" : "student",
      userID: Cookies.get("_akada__user_")
    }
    
    User.updateAccount(formData).then(res => {
      setLoading(false);
      if (res.type && res.type === "error") {
        return toast.error(res.message);
      }
      toast.success("Account updated successfully.");
      navigate("/users/dashboard");
    }).catch(err => {
      setLoading(false);
      const error = err.response?.data || {message: "An error occured, check your network and retry."};
      toast.error(error.message);
    })
  }

  return (
    <div>
      <div className="additionalinfo">
        <div className="head">
          <small>
            Welcome, <span className="text-sky-700">{form.fname} {form.lname}</span>
          </small>
          <h2 className="h2 tracking-tighter">Additional Information</h2>
          <p>We just need some basic information to get you started.</p>
        </div>

        <form className="form" onSubmit={handleSubmit}>
          <div className="form-field-wrapper">
            <div className="form-field">
              <label htmlFor="email">{(form.studentEmail && form.studentEmail.length > 1) ? "Personal" : "Student"} email</label>
              <input 
              type="email"
              onChange={inputChange}
              placeholder="Enter your email"
              name="email"
              required
              value={form.email}
              />
            </div>

            <div className="form-field">
              <label htmlFor="email">Phone number</label>
              <input
                type="tel"
                placeholder="Enter your phone number"
                name="phone"
                required
                onChange={inputChange}
                value={form.phone}
              />
            </div>

            <div className="flex flex-col gap-y-1">
              <small className="text-sm tracking-tight font-semibold">
                Select school
              </small>
              {
                schools && 
                <>
                <input 
                type="list" 
                name="school" 
                list="schlist" 
                className="form-control w-full"
                onChange={(e) => {
                  inputChange(e);
                  schools.find(sch => sch.name === e.target.value) && getSchoolDepartments(schools.find(sch => sch.name === e.target.value).id)
                }}
                />
                <datalist id="schlist">
                  {
                    schools.sort((a,b) => a.name - b.name).map(sch => {
                      return <option value={sch.name}></option>
                    })
                  }
                  
                </datalist>
                </>
              }
            </div>

            <div className="flex flex-col gap-y-1">
              <small className="text-sm tracking-tight font-semibold">
                Select course
              </small>
              {
                departments &&
                <select 
                name="department" 
                className="form-control w-full" 
                onChange={(e) => {
                  inputChange(e);
                }}
                >
                  {
                    departments.map(dept => {
                      return <option value={dept.dept_id}>{dept.name}</option>
                    })
                  }
                </select>
              }
            </div>

            <div className="flex flex-col gap-y-1">
              <small className="text-sm tracking-tight font-semibold">
                Select level
              </small>
              <DropListAutocomplete 
              data={levels} 
              onChange={(val) => setForm({...form, level: parseInt(val.slice(0,3))})}
              />
            </div>
          </div>

          <div className="flex center">
            <button className="btn mr-10" type="submit">{loading ? <Loader /> : 'Continue' }</button>
            <Link to={"/users/dashboard"} className=" btn-warnings" >Skip</Link>
          </div>
        </form>
      </div>
    </div>
  )
}

// Personal Email, Phone Number, School, Department, Level

export default AdditionalInfo
