import { BellIcon, LogoutIcon } from "@heroicons/react/outline"
import React, { useState, useRef, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"

import userImage from '../../../assets/image/default.png'
import Transition from "../../../utils/Transition"
import Cookies from "js-cookie"
import { User } from "../../../api"
import { toast } from "react-toastify"

function UserMenu() {
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [profile, setProfile] = useState({});

  const trigger = useRef(null)
  const dropdown = useRef(null)

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return
      setDropdownOpen(false)
    }
    document.addEventListener("click", clickHandler)
    return () => document.removeEventListener("click", clickHandler)
  })

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return
      setDropdownOpen(false)
    }
    document.addEventListener("keydown", keyHandler)
    return () => document.removeEventListener("keydown", keyHandler)
  })

  useEffect(() => {
    User.getProfile({userID: Cookies.get("_akada__user_")}).then(res => {
      if (res.type && res.type === "error") {
        toast.error(res.message);
        return navigate("/");
      }
      setProfile(res.profile);
      
    }).catch(err => {
      const error = err.response?.data || {message: "An error occured, check your network and retry."};
      toast.error('Access denied.');
      return navigate("/login");
    })
  
  }, [])

  return (
    <div className="relative inline-flex">
      <button
        ref={trigger}
        className="inline-flex justify-center items-center group"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <img
          className="w-6 h-6 rounded-full object-cover"
          src={userImage}
          alt="User"
        />
        <div className="flex items-center truncate">
          <span className="truncate ml-2 text-sm font-medium group-hover:text-blue-200">
            {profile?.fname}
          </span>
          <svg
            className="w-3 h-3 shrink-0 ml-1 fill-current text-blue-400"
            viewBox="0 0 12 12"
          >
            <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
          </svg>
        </div>
      </button>

      <Transition
        className="origin-top-right z-10 absolute top-full right-0 min-w-44 bg-white border border-blue-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-blue-200">
            <div className="font-semibold text-blue-900">{profile?.fname}</div>
          </div>
          <ul>
            <li>
              <Link
                className="font-medium text-sm text-gray-500 hover:text-blue-800 flex gap-x-2 items-center py-1 px-3"
                to="#"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                <BellIcon className="text-gray-400 h-4" />
                <span>Notifications</span>
              </Link>
            </li>
            <li>
              <Link
                className="font-medium text-sm text-gray-500 hover:text-blue-800 flex gap-x-2 items-center py-1 px-3"
                to="/"
                onClick={() => {
                  setDropdownOpen(!dropdownOpen);
                  Cookies.remove("_akad__usr__tk_");
                  Cookies.remove("_akada__user_");
                } }
              >
                <LogoutIcon className="text-gray-400 h-4" />
                <span>Sign Out</span>
              </Link>
            </li>
          </ul>
        </div>
      </Transition>
    </div>
  )
}

export default UserMenu
