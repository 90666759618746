import { MailIcon, PhoneIcon } from "@heroicons/react/outline"
import { Link } from "react-router-dom"
import Whitelogo from "../../assets/image/logo-white.png";

const Footer = () => {
  return (
    <div className="home-footer-container">
      <div className="border-b border-gray-700 home-footer">
      <div className="site-info">
        <Link to="/">
          <img src={Whitelogo} alt="" className="h-10" />
        </Link>

        <div className="text-base">
        AkadaMetrix is your trusted and first national platform to capture impartial students’ academic and social experience in Nigeria.
        </div>

        <div className="flex gap-2 items-center text-white">
          <a href="https://facebook.com">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="social-icon"
              viewBox="0 0 24 24"
            >
              <path d="M12.001,2.002c-5.522,0-9.999,4.477-9.999,9.999c0,4.99,3.656,9.126,8.437,9.879v-6.988h-2.54v-2.891h2.54V9.798	c0-2.508,1.493-3.891,3.776-3.891c1.094,0,2.24,0.195,2.24,0.195v2.459h-1.264c-1.24,0-1.628,0.772-1.628,1.563v1.875h2.771	l-0.443,2.891h-2.328v6.988C18.344,21.129,22,16.992,22,12.001C22,6.479,17.523,2.002,12.001,2.002z" />
            </svg>
          </a>

          <a href="https://twitter.com">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="social-icon"
              viewBox="0 0 24 24"
            >
              <path d="M19.633,7.997c0.013,0.175,0.013,0.349,0.013,0.523c0,5.325-4.053,11.461-11.46,11.461c-2.282,0-4.402-0.661-6.186-1.809	c0.324,0.037,0.636,0.05,0.973,0.05c1.883,0,3.616-0.636,5.001-1.721c-1.771-0.037-3.255-1.197-3.767-2.793	c0.249,0.037,0.499,0.062,0.761,0.062c0.361,0,0.724-0.05,1.061-0.137c-1.847-0.374-3.23-1.995-3.23-3.953v-0.05	c0.537,0.299,1.16,0.486,1.82,0.511C3.534,9.419,2.823,8.184,2.823,6.787c0-0.748,0.199-1.434,0.548-2.032	c1.983,2.443,4.964,4.04,8.306,4.215c-0.062-0.3-0.1-0.611-0.1-0.923c0-2.22,1.796-4.028,4.028-4.028	c1.16,0,2.207,0.486,2.943,1.272c0.91-0.175,1.782-0.512,2.556-0.973c-0.299,0.935-0.936,1.721-1.771,2.22	c0.811-0.088,1.597-0.312,2.319-0.624C21.104,6.712,20.419,7.423,19.633,7.997z" />
            </svg>
          </a>
        </div>
      </div>

      <div className="site-links">
        <div>
          <h3>Pages</h3>

          <ul>
            <li>
              <a  href="/about">About</a>
            </li>
            <li>
              <a href="/signup">Register</a>
            </li>
            <li>
              <a href="/terms-and-conditions">Terms and Conditions</a>
            </li>
            <li>
              <a href="/faqs">FAQs</a>
            </li>
          </ul>
        </div>

        <div>
          <h3>Contact</h3>

          <ul>
            {/* <a
              href="tel:+2348056783451"
              className="flex flex-row items-center gap-2"
            >
              <PhoneIcon className="h-5" />
              <span>+2348056783451</span>
            </a> */}
            <a href="mailto:info@akadametrix.com">
              <MailIcon className="h-5" />
              <span>info@akadametrix.com</span>
            </a>
          </ul>
        </div>
      </div></div>

      <div className="text-sm py-2">&copy; 2023, Akadametrix. All rights reserved.</div>
    </div>
  )
}

export default Footer
