import { Link, useNavigate } from "react-router-dom"
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline"
import Helmet from 'react-helmet'
import { useEffect, useState } from "react"
import Logo from "../../../assets/Logo"
import "../auth.scss"
import Loader from "../../../components/Loader"
import Cookies from "js-cookie";
import { User } from "../../../api"
import { toast } from "react-toastify"
import Whitelogo from "../../../assets/image/Whitelogo.svg";

const Login = () => {
  const [show, setShow] = useState(false);
  const [form, setForm] = useState({email: "", password: ""});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0,0);
  }, [])
  useEffect(() => {
    if (Cookies.get("_akada__user_") && Cookies.get("_akada__user_").length >= 1) {
      return navigate("/users/dashboard");
    }
  }, [navigate])


  const inputChange = (e) => {
    setForm({...form, [e.target.name]: e.target.value});
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    User.login(form).then(res => {
      setLoading(false);
      if (res.type && res.type === "error") {
        return toast.error(res.message);
      }
      Cookies.set("_akada__user_", res.user);
      Cookies.set("_akad__usr__tk_", res.token);
      
      navigate("/users/dashboard");
    }).catch(err => {
      setLoading(false);
      const resp = err.response?.data || {message: "An error occured, please check your network and retry."};
      if (resp.deactivated) {
        return toast.error("Your account has been deactivated, please contact support");
      } else if (resp.unverified) {
        Cookies.set("_v__usr_", resp.user);
        toast.warn("You need to verify your email to login");
        return navigate("/verify-email")
      }
      toast.error(resp.message);
    })

  }

  return (
    <>
      <Helmet>
        <title>Log in | Acadametrics</title>
      </Helmet>
      <div className="auth">
        <div className="auth-banner">
          <div className="auth-header">
            <Link to="/">
              {/* <Logo className="h-10 text-white logo-dark" /> */}
              <img src={Whitelogo} alt="" className="h-13"/>
            </Link>

            <span>Rate and assess your school programmes</span>
          </div>

          <div className="auth-footer">
            <Link to="/terms-and-conditions">PRIVACY POLICY </Link>
            <span>.</span>
            <Link to="/terms-and-conditions"> TERMS OF SERVICE</Link>
          </div>
        </div>

        <form className="auth-form" onSubmit={handleSubmit}>
          <Link className="self-center block lg:hidden mb-8" to="/">
            <Logo className="h-10 logo" />
          </Link>

          <div className="auth-form__header">
            <h4 className="h4">Login</h4>
            <span className="text-gray-400">
              You can login with your registered account
            </span>
          </div>

          <div className="auth-field">
            <span>
              <label htmlFor="email">Email</label>
            </span>
            <input
              id="email"
              name="email"
              type="email"
              placeholder="example@example.com"
              required
              onChange={inputChange}
              value={form.email}
            />
          </div>

          <div className="auth-field forgot">
            <span className="forgot-label">
              <label htmlFor="password">Password</label>
              <span className="text-blue-500">Forgot password?</span>
            </span>

            <div>
              <input
                id="password"
                name="password"
                type={show ? "text" : "password"}
                placeholder="Choose a password"
                required
                onChange={inputChange}
                value={form.password}
              />

              <button className="btn" onClick={() => setShow(!show)} type="button">
                {show ? (
                  <EyeOffIcon className="h-4" />
                ) : (
                  <EyeIcon className="h-4" />
                )}
              </button>
            </div>
          </div>

          <button className="submit-button" type="submit">
            {loading ? <Loader /> : 'Login' }
          </button>

          <span className="auth-direct">
            Don't have an account?
            <Link to="/signup">
              Create one!
            </Link>
          </span>
          
          <div className="auth-foote text-center">
            <Link to="/terms-and-conditions"> TERMS AND CONDITIONS</Link>
          </div>
        </form>
      </div>
    </>
  )
}

export default Login
