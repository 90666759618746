import React from "react"
import { Link } from "react-router-dom"

import NigeriaMap from "../../../../components/NigeriaMap"
import lockSvg from "../../../../assets/image/lock.svg"

const Metrics = () => {
  return (
    <div className="metrics">
      {/* <div className="flex flex-col lg:flex-row gap-y-10 items-start justify-between p-2">
        <div className="instructions">
          <h4 className="h4 font-medium text-gray-500 mt-6">
            How to get started
          </h4>

          <div className="instruction-wrapper">
            <div className="instruction">
              <span className="number">1</span>
              <span>
                <b><Link to="/signup">Sign up</Link></b> on our website
              </span>
            </div>

            <div className="instruction">
              <span className="number">2</span>
              <span>Verify your account and details</span>
            </div>

            <div className="instruction">
              <span className="number">3</span>
              <span>Start submitting feedback</span>
            </div>
          </div>

        </div>

        <NigeriaMap />
      </div> */}
      <div className="bg-cyan px-8 py-6 rounded-lg">
        <h2 className="h3 text-white">Your privacy is of utmost importance to us.</h2>
        <p style={{fontSize: '1.1rem'}}>We are committed to ensuring the confidentiality and anonymity of all survey participants. Your privacy is of utmost importance to us, and your personal information will be protected and kept strictly confidential.</p>
        <div className="flex gap-4 items-center flex-wrap mt-5">

        <img className="h-20" alt="Security lock" src={lockSvg} />
          <Link to={"/submit-review"} className="px-6 py-3 inline-block bg-white text-black font-bold rounded-lg h-12">Submit feedback</Link>
        </div>
      </div>
    </div>
  )
}

export default Metrics
