import { Tab } from "@headlessui/react"
import ChangePassword from "./components/ChangePassword"
import UpdateProfile from "./components/UpdateProfile"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const Settings = () => {
  const tabs = ["Update Profile", "Change Password"]

  return (
    <div className="w-full">
      <h1 className="h1 pb-2 border-b border-gray-200">
        Settings
      </h1>

      <div className="w-full mt-6">
        <Tab.Group>
          <Tab.List className="flex bg-gray-200">
            {tabs.map((tab, index) => (
              <Tab
                key={index}
                className={({ selected }) =>
                  classNames(
                    "w-full p-4 text-md font-medium leading-5",
                    selected
                      ? "bg-gray-800 text-gray-50"
                      : "hover:bg-gray-100 text-gray-900 hover:text-gray-700"
                  )
                }
              >
                {tab}
              </Tab>
            ))}
          </Tab.List>

          <Tab.Panels className="mt-2">
            <Tab.Panel>
              <UpdateProfile />
            </Tab.Panel>
            <Tab.Panel>
              <ChangePassword />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  )
}

export default Settings
