import React, { useEffect, useState } from "react";
import "./RequestData.scss";
import Loader from "../../../components/Loader";
import { Message, Reviews } from "../../../api";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import SingleChoice from "../../../components/FeedbackElements/SingleChoice";

const WebsiteReview = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({easeLevel: "", recommend: "", otherData: "", improvement: ""});

  const handleChange = (e) => {
    setForm((form) => ({...form, [e.target.name]: e.target.value}));
  }
  
  useEffect(() => {
    window.scrollTo(0,0);
  }, [])

  const userType = Cookies.get("_akada__user_") ? "registered" : "anonymous";
  const userID = Cookies.get("_akada__user_") || "";

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    Reviews.submitReview({...form, userType, userID}).then(res => {
      setLoading(false);
      if (res.type === "error") {
        return toast.error(res.message);
      }
      Swal.fire({
        icon: "success", 
        title: "Thank you!", 
        text: "We have received your feedback, we will work with it to improve experience."
      }).then(() => {
        navigate("/");
      })
    }).catch((err) => {
      setLoading(false);
      const error = err.response?.data || {message: "Network error"};
      toast.error(error.message);
    })

  }

  return (
    <div>

      <div className="website-review">
        <div className="head">
          <h2 className="h2 tracking-tighter">Leave a review</h2>
          <p>How was your experience with the website?</p>
        </div>

        <form className="form" onSubmit={handleSubmit}>
          <div className="form-field-wrapper">
            <div>
              <label htmlFor="email" className=" text-gray-600">Would you recommend this website to anyone?</label>
              <SingleChoice config={{options: ["Yes", "No"], id: "recommend"}} handleChange={handleChange} value={form.recommend} />
            </div>

            <div>
              <label htmlFor="email" className=" text-gray-600">How easy was it to use this website?</label>
              <select 
              name="easeLevel" 
              value={form.easeLevel}
              id="level"
              className="w-full p-4 bg-gray-50 rounded-lg py-2 px-4 text-base ring-1 ring-gray-200 tracking-tight"
              onChange={handleChange} 
              required
              >
                <option value="" selected>--Select option--</option>
                <option value="Very Difficult">Very Difficult</option>
                <option value="Difficult">Difficult</option>
                <option value="Average">Average</option>
                <option value="Easy">Easy</option>
                <option value="Very easy">Very easy</option>
              </select>
            </div>

            <div>
              <label htmlFor="subject" className=" text-gray-600">What other data would you like us to track?</label>
              <input
              className="w-full p-4 bg-gray-50 rounded-lg py-2 px-4 text-base ring-1 ring-gray-200 tracking-tight"
              type="text" 
              placeholder="Enter here.."
              onChange={handleChange}
              name="otherData"
              value={form.otherData}
              required
              />
            </div>

            <div className="flex flex-col gap-y-1 w-full">
              <label htmlFor="message" className=" text-gray-600">What improvement would you like to see on this website?</label>
              <textarea
                id="message"
                className="w-full h-60 p-4 bg-gray-50 rounded-lg py-2 px-4 text-base ring-1 ring-gray-200 tracking-tight"
                placeholder="Type in here..."
                onChange={handleChange}
                name="improvement"
                value={form.improvement}
                required
              />
            </div>
          </div>

          <button className="btn" type="submit">
            {loading ? <Loader /> : "Submit Review"}
          </button>
        </form>
      </div>
    </div>
  )
}

export default WebsiteReview
