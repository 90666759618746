import React, { useState } from "react"
import { SearchIcon } from "@heroicons/react/outline"
import { Link } from "react-router-dom"

import Logo from "../../../assets/Logo"
import UserMenu from "./UserMenu"
import SearchModal from "./SearchModal"
import Whitelogo from "../../../assets/image/Whitelogo.svg";

const TopNav = () => {
  const [searchModalOpen, setSearchModalOpen] = useState(false)

  return (
    <div className="topnav">
      <Link className="self-center" to="/">
          <img src={Whitelogo} alt="" className="h-10" />
        {/* <Logo className="h-7 text-white logo-dark" /> */}
      </Link>

      <div className="topnav-icon-group">
        <button
          className={`flex items-center justify-center transition duration-150 rounded-full ml-3 ${
            searchModalOpen && "opacity-50"
          }`}
          onClick={(e) => {
            e.stopPropagation()
            setSearchModalOpen(true)
          }}
          aria-controls="search-modal"
        >
          <span className="sr-only">Search</span>
          <SearchIcon className="h-5 text-white" />
        </button>

        <SearchModal
          id="search-modal"
          searchId="search"
          modalOpen={searchModalOpen}
          setModalOpen={setSearchModalOpen}
        />

        <UserMenu />
      </div>
    </div>
  )
}

export default TopNav
