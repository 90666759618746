import React, { useState } from "react"
import { Outlet } from "react-router-dom"
import AdminHeader from "../components/AdminHeader"
import AdminSidebar from "../components/AdminSidebar"

const AdminLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (
    <div className="flex h-screen overflow-hidden">
      <AdminSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <AdminHeader
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        
        <main>
          <Outlet />
        </main>
      </div>
    </div>
  )
}

export default AdminLayout
