import { Disclosure } from "@headlessui/react"
import { ChevronRightIcon, ChevronUpIcon } from "@heroicons/react/outline"
import { Link } from "react-router-dom"
import AllRating from "../../../components/AllRating"

const data = {
  Science: {
    Departments: {
      Anthropology: {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      Archaeology: {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      "Archaeology Combined with Geology, Geography, Botany and Zoology": {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      Botany: {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      Chemistry: {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      "Computer Science": {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      Geography: {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      Geology: {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      "Industrial Chemistry": {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      Mathematics: {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      Microbiology: {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      Physics: {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      Statistics: {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      Zoology: {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
    },
  },
  "Social Sciences": {
    Departments: {
      Economics: {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      Geography: {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      Sociology: {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      Psychology: {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      "Political Science": {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
    },
  },
  Technology: {
    Departments: {
      "Agricultural and Environmental Engineering": {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      "Civil Engineering": {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      "Electrical and Electronics Engineering": {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
          "500-level": [],
        },
      },
      "Food Technology": {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
          "500-level": [],
        },
      },
      "Industrial Production Engineering": {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
          "500-level": [],
        },
      },
      "Mechanical Engineering": {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
          "500-level": [],
        },
      },
      "Petroleum Engineering": {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
          "500-level": [],
        },
      },
      "Wood Production Engineering": {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
          "500-level": [],
        },
      },
    },
  },
  Education: {
    Departments: {
      "Adult Education": {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      "Teacher Education: Arts, Social Sciences,Science, Pre-Primary Education":
        {
          Courses: {
            "100-level": [],
            "200-level": [],
            "300-level": [],
            "400-level": [],
          },
        },
      "Educational Management": {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      "Health Education": {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      "Human Kinetics": {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      "Guidance and Counselling": {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      "Library Studies": {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      "Special Education": {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
    },
  },
  Law: {
    Departments: {
      Law: {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
          "500-level": [],
        },
      },
    },
  },
  "Veterinary Medicine": {
    Departments: {
      "Veterinary Medicine": {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
          "500-level": [],
        },
      },
    },
  },
  Arts: {
    Departments: {
      "Arabic Language and literature": {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      "Islamic Studies": {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      Archaeology: {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      "Archaeology Combined: History, Classics, Religious Studies ": {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      Anthropology: {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      Classics: {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      "Religious Studies": {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      "Communication and Language Arts": {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      "English Language and Literature": {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      Philosophy: {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      "Linguistics and African Studies: Linguistics, Yoruba, Igbo, History": {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      History: {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      "European Studies: French, Russian, German, Theatre Arts": {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
    },
  },
  "Agriculture and Forestry": {
    Departments: {
      Agriculture: {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
          "500-level": [],
        },
      },
      "Fisheries and Wildlife Management": {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
          "500-level": [],
        },
      },
      "Forestry Resources Management": {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
          "500-level": [],
        },
      },
    },
  },
  Pharmacy: {
    Departments: {
      Pharmacy: {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
          "500-level": [],
        },
      },
    },
  },
  Medicine: {
    Departments: {
      "Medicine and Surgery": {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
          "500-level": [],
          "600-level": [],
        },
      },
      Dentistry: {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
          "500-level": [],
        },
      },
      Physiotherapy: {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
          "500-level": [],
        },
      },
      Biochemistry: {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      Physiology: {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      "Human Nutrition": {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
        },
      },
      "Nursing Science": {
        Courses: {
          "100-level": [],
          "200-level": [],
          "300-level": [],
          "400-level": [],
          "500-level": [],
        },
      },
    },
  },
}

const Schools = () => {
  return (
    <div>
      <div className="p-4 flex flex-wrap gap-2 justify-between items-end w-full bg-gray-50 border-b border-gray-300 mb-2">
        <div>
          <h1 className="text-4xl font-bold tracking-tighter">University of Ibadan</h1>
          <small>Oduduwa Road, 200132, Ibadan, Oyo</small>
        </div>

        <Link to="reviews" className="bg-sky-700 text-white px-4 py-2 flex items-center rounded-lg shadow-lg">
          View reviews <ChevronRightIcon className="text-white h-6" />
        </Link>
      </div>

      <AllRating rating={4.75} totalRatings="1,715" />

      <div>
        <h5 className="h5 font-semibold px-4">Faculty and Departments</h5>
        <div className="w-full flex flex-col gap-2 p-4 bg-sky-50">
          {Object.keys(data).map((uni) => (
            <Disclosure key={uni}>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex w-full justify-between rounded-lg bg-sky-200 px-4 py-2 text-left text-sm font-medium text-sky-900 hover:bg-sky-200 focus:outline-none focus-visible:ring focus-visible:ring-sky-500 focus-visible:ring-opacity-75 mb-1">
                    <span>{uni}</span>
                    <ChevronUpIcon
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-sky-500`}
                    />
                  </Disclosure.Button>
                  {Object.keys(data[uni].Departments).map((dept) => (
                      <Disclosure.Panel className="px-4 py-1 text-sm text-gray-800">
                        <span>{dept}</span>
                      </Disclosure.Panel>
                  ))}
                </>
              )}
            </Disclosure>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Schools
