import { Link, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import Helmet from 'react-helmet'
import Logo from "../../../assets/Logo"
import "../auth.scss"
import Loader from "../../../components/Loader"
import { Feedback, User } from "../../../api"
import { toast } from "react-toastify"
import Cookies from "js-cookie";

const VerifyEmail = () => {
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [keyLoading, setKeyLoading] = useState(false);


  useEffect(() => {
    window.scrollTo(0,0);
    if (!Cookies.get("_v__usr_") || Cookies.get("_v__usr_").length < 1) {
      return navigate("/signup");
    }
  }, [navigate])

  const resendCode = (e) => {
    e.preventDefault();
    setKeyLoading(true);
    User.resendEmailVerificationKey({userID: Cookies.get("_v__usr_")}).then(res => {
      setKeyLoading(false);
      if (res.type && res.type === "error") {
        return toast.error(res.message);
      }
      toast.success("Verification key has been sent to given email");

    }).catch(err => {
      setKeyLoading(false);
      const error = err.response?.data || {message: "An error occured, please check your network and retry."};
      toast.error(error.message);
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    User.verifyEmail({userID: Cookies.get("_v__usr_"), vKey: code}).then(res => {
      setLoading(false);
      if (res.type && res.type === "error") {
        const error = res || { message: "An error occured, please check your network and retry." };
        return toast.error(error.message);
      }

      Cookies.set("_akada__user_", res.user);
      Cookies.set("_akad__usr__tk_", res.token);

      const pendingSubmission = Cookies.get("pend__sub");
      if ( pendingSubmission && !isNaN(parseInt(pendingSubmission)) ) {
        Feedback.updateReviewer({user: res.user, submission: pendingSubmission })
        .then(() => {
          console.log("update done!");
        }).catch(err => {
          console.log(err);
        })
      }

      Cookies.remove("_v__usr_");
      toast.success("Email verified successfully!");
      navigate("/additional-info");

    }).catch(err => {
      setLoading(false);
      const error = err.response?.data || { message: "An error occured, please check your network and retry." }
      toast.error(error.message);
    })
  }


  return (
    <>

      <Helmet>
        <title>Verify Your Email | Acadametrics</title>
      </Helmet>
      <div className="auth">
        <div className="auth-banner">
          <div className="auth-header">
            <Link to="/">
              <Logo className="h-10 text-white logo-dark" />
            </Link>

            <span>Rate and assess your school programmes</span>
          </div>

          <div className="auth-footer">
            <Link to="/">PRIVACY POLICY </Link>
            <span>.</span>
            <Link to="/"> TERMS OF SERVICE</Link>
          </div>
        </div>

        <form className="auth-form" onSubmit={handleSubmit}>
          <Link className="self-center mb-8 block lg:hidden" to="/">
            <Logo className="h-10 logo" />
          </Link>

          <div className="auth-form__header">
            <h4 className="h4">Verify Your Email</h4>
            <span className="text-gray-700 text-lg">We have sent a code to the email provided, please check and enter the code here.</span>
          </div>

          <div className="auth-field">
            <span>
              <label htmlFor="email">Verification Code</label>
            </span>
            <input
              id="code"
              name="code"
              type="text"
              placeholder="Enter here.."
              required
              onChange={(e) => setCode(e.target.value)}
              value={code}
            />
          </div>

          {/* <div className="auth-field">
            <span>
              <label htmlFor="email">Last Name</label>
            </span>
            <input
              id="lastname"
              name="lname"
              type="text"
              placeholder="Ex: John"
              required
              onChange={inputChange}
              value={form.lname}
            />
          </div> */}


          <button className="submit-button" type="submit">
            {loading ? <Loader /> : 'Verify Email' }
          </button>

          {
            keyLoading ? <span className="auth-direct">Resending code...</span> : 
            <span className="auth-direct">
              Didn't receive code?{" "}
              <a href="#" onClick={resendCode}>
                Resend Code
              </a>
            </span>
          }
        </form>
      </div>
    </>
  )
}

export default VerifyEmail
