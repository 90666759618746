import React from 'react'
import BottomNav from './components/BottomNav'
import TopNav from './components/TopNav'

import './UsersNavbar.scss'

const UsersNavbar = () => {
  return (
    <div className='users-navbar'>
        <TopNav />

        <BottomNav />
    </div>
  )
}

export default UsersNavbar