import { MailIcon } from '@heroicons/react/outline'

const Subscribe = () => {
  return (
    <div className='subscribe'>
      <div className='subscribe-head'>
        <h1 className='h1'>
          Subscribe to our newsletter
        </h1>

        <small>Get weekly updates about acadametrics in your mail box.</small>
      </div>

      <div className='subscribe-box'>
        <MailIcon className='h-5 text-gray-500 absolute top-3 left-4' />
        <input type="email" placeholder='youremail@email.com' />
        <button>Subscribe</button>
      </div>
    </div>
  )
}

export default Subscribe
