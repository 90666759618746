import FilterableTable from "react-filterable-table"

import "./Users.scss"

import usersData from "./usersData.json"
import AdminPageHeader from "../../../components/AdminPageHeader"
import { Link } from "react-router-dom"

const Users = () => {
  const renderName = (props) => {
    return <Link to={`/admin/users/${props.record.id}`}>{props.value}</Link>
  }
  const fields = [
    {
      name: "user_type",
      displayName: "User Type",
      inputFilterable: true,
      sortable: true,
    },
    {
      name: "user_id",
      displayName: "User Id",
      inputFilterable: true,
      sortable: true,
      render: renderName,
    },
    {
      name: "institution",
      displayName: "Institution",
      inputFilterable: true,
      exactFilterable: true,
      sortable: true,
    },
    {
      name: "joined_date",
      displayName: "Joined date",
      inputFilterable: true,
      exactFilterable: true,
      sortable: true,
    },
  ]
  return (
    <div className="users-admin">
      <AdminPageHeader
        title="Manage Users"
        description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Et, animi? Distinctio ducimus architecto rem hic? Explicabo repellat tempora veritatis minima quis dicta delectus incidunt at. Maxime fuga sediure, sunt est deserunt labore illum facilis excepturi iusto"
        stat={{
          value: "12,245",
          text: "Users",
        }}
      />

      <div className="p-4">
        <FilterableTable
          namespace="Users"
          initialSort="name"
          data={usersData}
          fields={fields}
          noRecordsMessage="There are no users to display"
          noFilteredRecordsMessage="No user match your filters!"
        />
      </div>
    </div>
  )
}

export default Users
