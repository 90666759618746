import {
  BookOpenIcon,
  MailIcon,
  MailOpenIcon,
  MapIcon,
  PhoneIcon,
  UserIcon,
} from "@heroicons/react/outline"
import React from "react"

const UserProfile = () => {
  return (
    <div className="p-4">
      <div className="flex flex-wrap gap-x-10 gap-y-4 items-start">
        <img
          className="h-40 w-40 object-cover rounded-full"
          src="/images/student.jpg"
          alt=""
        />
        <div className="flex flex-col gap-2">
          <div>
            <h1 className="text-3xl">Abdulrasheed Ahmad</h1>

            <div className="flex flex-wrap gap-2">
              <span className="flex gap-2 text-gray-500">
                <MapIcon className="h-5" /> University of Ibadan
              </span>
              <span className="flex gap-2 text-gray-500">
                <BookOpenIcon className="h-5" /> Mechanical Engineering
              </span>
              <span className="flex gap-2 text-gray-500">
                <MailIcon className="h-5" /> rasheedahmad@ui.edu.ng
              </span>
              <span className="flex gap-2 text-gray-500">
                <MailOpenIcon className="h-5" /> rasheedahmad@gmail.com
              </span>
              <span className="flex gap-2 text-gray-500">
                <PhoneIcon className="h-5" /> +2348034567834
              </span>
            </div>
          </div>

          <div className="flex flex-wrap items-center gap-2">
            <button className="flex bg-black px-4 py-2 rounded-lg text-white gap-2 font-semibold">
              <UserIcon className="h-5" /> Update Information
            </button>
            <button className="flex bg-sky-600 px-4 py-2 rounded-lg text-white gap-2 font-semibold">
              <MailIcon className="h-5" /> Send Mail
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserProfile
