import "./FeedbackPage.scss"
import { Tab } from "@headlessui/react"
import Submissions from "./components/Submissions"
import AdminPageHeader from "../../../components/AdminPageHeader"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const tabs = ["Submissions"]

const FeedbackPage = () => {
  return (
    <div className="feedback-page">
      <AdminPageHeader
        title="Institution Feedback"
        description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Et, animi? Distinctio ducimus architecto rem hic? Explicabo repellat tempora veritatis minima quis dicta delectus incidunt at. Maxime fuga sediure, sunt est deserunt labore illum facilis excepturi iusto"
        stat={{
          value: 1_245,
          text: "Submissions",
        }}
      />

      <Tab.Group>
        <Tab.List className="flex bg-sky-100 sticky top-16 z-10">
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              className={({ selected }) =>
                classNames(
                  "py-2 px-4 text-sm font-medium",
                  selected
                    ? "bg-sky-800 text-sky-50"
                    : "hover:bg-sky-100 text-sky-900 hover:text-sky-700"
                )
              }
            >
              {tab}
            </Tab>
          ))}
        </Tab.List>

        <Tab.Panels className="bg-gray-100">
          <Tab.Panel>
            <Submissions />
          </Tab.Panel>
          {/* <Tab.Panel>
            Data
          </Tab.Panel> */}
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}

export default FeedbackPage
