import React, { useEffect, useState } from "react";
import "./RequestData.scss";
import Loader from "../../../components/Loader";
import { Message } from "../../../api";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const ContactUs = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({name: "", email: "", level: "medium", subject: "", message: ""});

  const handleChange = (e) => {
    setForm((form) => ({...form, [e.target.name]: e.target.value}));
  }
  
  useEffect(() => {
    window.scrollTo(0,0);
  }, [])

  const userType = Cookies.get("_akada__user_") ? "registered" : "anonymous";
  const userID = Cookies.get("_akada__user_") || "";
  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    Message.sendContactMessage({...form, userType, userID}).then(res => {
      setLoading(false);
      if (res.type === "error") {
        return toast.error(res.message);
      }
      Swal.fire({
        icon: "success", 
        title: "Message Sent", 
        text: "We have received your message, a response will be sent across to the email provided."
      }).then(() => {
        navigate("/");
      })
    }).catch((err) => {
      setLoading(false);
      console.log(err);
      const error = err.response?.data || {message: "Network error"};
      toast.error(error.message);
    })

  }

  return (
    <div>
      {/* *<div className="requestdata-header">
        <Link
          className="nav-logo outline-none border-b border-gray-200 pb-6"
          to="/"
        >
          <Logo className=" logo-dark h-8 text-white logo" />
        </Link>

        <Link to="/" className="backtohome">
          <HomeIcon className="h-4" />
          <span>Back to home</span>
        </Link>
      </div>* */}

      <div className="requestdata">
        <div className="head">
          <h2 className="h2 tracking-tighter">Send us a message</h2>
          <p>We will respond as soon as we receive your message.</p>
        </div>

        <form className="form" onSubmit={handleSubmit}>
          <div className="form-field-wrapper">
            <div className="form-field">
              <label htmlFor="email" className=" text-gray-600">Person or Organization Name</label>
              <input
              type="text"
              placeholder="Enter your name..."
              onChange={handleChange}
              name="name"
              value={form.name}
              required />
            </div>

            <div className="form-field">
              <label htmlFor="email" className=" text-gray-600">Personal email</label>
              <input
              type="email"
              placeholder="Enter your email"
              onChange={handleChange}
              name="email"
              value={form.email}
              required />
            </div>

            <div className="form-field">
              <label htmlFor="subject" className=" text-gray-600">Message subject</label>
              <input
              type="text" 
              placeholder="Enter your subject"
              onChange={handleChange}
              name="subject"
              value={form.subject}
              required
              />
            </div>

            <div className="flex flex-col gap-y-1 w-full">
              <label htmlFor="message" className=" text-gray-600">Message body</label>
              <textarea
                id="message"
                className="w-full h-60 p-4 bg-gray-50 rounded-lg py-2 px-4 text-base ring-1 ring-gray-200 tracking-tight"
                placeholder="Type in here..."
                onChange={handleChange}
                name="message"
                value={form.message}
                required
              />
            </div>

            <div className="flex flex-col gap-y-1 w-full">
              <label htmlFor="level" className=" text-gray-600">Level of Importance</label>
              <select 
              name="level" 
              value={form.level}
              id="level"
              className="w-full p-4 bg-gray-50 rounded-lg py-2 px-4 text-base ring-1 ring-gray-200 tracking-tight"
              onChange={handleChange} 
              required
              >
                <option value="medium">Averagely Important</option>
                <option value="low">Not really Important</option>
                <option value="high">Highly Important</option>
              </select>
            </div>

          </div>

          <button className="btn" type="submit">
            {loading ? <Loader /> : "Send message"}
          </button>
        </form>
      </div>
    </div>
  )
}

// Personal Email, Phone Number, School, Department, Level

export default ContactUs
