import React, {useState, useEffect} from "react"
import ReactApexChart from "react-apexcharts"


const config = (horizontal = false) => {
    return {
      series: [
        {
          name: "Rate",
          data: [1400, 1430, 1448, 770, 540, 580, 690],
        },
      ],
      options: {
        plotOptions: {
          bar: {
            horizontal,
            distributed: true,
            startingShape: "flat",
            endingShape: "rounded",
            dataLabels: {
              position: "bottom",
            },
            barHeight: horizontal ? "70" : '',
          },
        },
        xaxis: {
          categories: [
            "UI",
            "UNILAG",
            "FUTA",
            "UNILAG",
            "UNN",
            "UNIOSUN",
            "UNIABUJA"
          ],
          labels: {
            style: {
              fontFamily: "Red Hat Display",
            },
          },
          group: {
            style: {
              fontFamily: "Red Hat Display"
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              fontFamily: "Red Hat Display",
            },
          },
        },
        dataLabels: {
          style: {
            fontFamily: "Red Hat Display",
          },
        },
        tooltip: {
          style: {
            fontFamily: "Red Hat Display",
          },
        },
      },
    }
  }

const SubChart = () => {
    const [configuration, setConfiguration] = useState(config())
  
    useEffect(() => {
      if (window.innerWidth > 850) return
  
      setConfiguration(config(true))
      return
    }, [])
  
    useEffect(() => {
      window.addEventListener("resize", (e) => {
        if (e.target.innerWidth > 850) {
          setConfiguration(config(false))
  
          return
        }
  
        setConfiguration(config(true))
        return
      })
  
      return
    }, [])

    return (
        <div className="flex justify-around gap-10 px-10 py-10 items-center flex-wrap">
            <div className="homechart py-5 rounded-lg md:w-[40%]">
                <h5 className="h5 tracking-tighter">
                    Hostel availability in Institutions
                </h5>
                <ReactApexChart
                    options={configuration.options}
                    series={configuration.series}
                    height={configuration.options.plotOptions.bar.horizontal ? '450' : '450'}
                    type="bar"
                    key={configuration.options.plotOptions.bar.horizontal.toString()}
                />
            </div>

            <div className="md:w-[50%]">
                <h4 className="h4">MAKE A DIFFERENCE</h4>

                <p className="my-3">
                Now is the time to share your experiences, insights, and suggestions. By taking part in this survey, you are playing an active role in shaping the future of education in Nigeria. Your voice matters, and together, we can create a more rewarding and enriching experience for all students.
                </p>

                <p className="my-3">
                Join us in this exciting journey of enhancing the national student experience in Nigeria. Together, we can make a positive impact and create a brighter future for students across the country.
                </p>
            </div>

        </div>
    )
}

export default SubChart
