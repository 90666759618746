import React, { useEffect } from "react"
import { SwitchVerticalIcon } from "@heroicons/react/outline"

import "./Dashboard.scss"
import DashboardChart from "../components/DashboardChart"
import { useState } from "react"
import DashboardHeader from "../components/DashboardHeader"

const AdminDashboard = () => {
  const [chartType, setChartType] = useState(0)
  const types = ["line", "bar"]

  const handleChartChange = () => {
    if (chartType === 0) {
      setChartType(chartType + 1)
      return
    }

    if (chartType === types.length - 1) {
      setChartType(0)
      return
    }

    setChartType(chartType + 1)
    return
  }

  useEffect(() => {
    console.log(chartType)
  }, [chartType])

  return (
    <div className="admindashboard">
      <DashboardHeader />

      <div className="content">

        <div className="chart">
          <div className="head">
            <h4>
              Verified users in the last 6 months.
            </h4>
            <button onClick={handleChartChange}>
              <span>{`${types[chartType].toUpperCase()} CHART`}</span>
              <SwitchVerticalIcon className="h-5" />
            </button>
          </div>
          <DashboardChart type={types[chartType]} />
        </div>

      </div>
    </div>
  )
}

export default AdminDashboard
