import React, { useEffect, useState } from "react"

import "./UsersDashboard.scss"

import RateSvg from "../../../assets/image/graph-rate.svg"
import BookCheckSvg from "../../../assets/image/book-check.svg"
import Cookies from "js-cookie";
import { User } from "../../../api"
import { toast } from "react-toastify"
import { Link, useNavigate } from "react-router-dom";
import { UserAddIcon, ChartBarIcon } from "@heroicons/react/solid";

const UsersDashboard = () => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState({});
  
  useEffect(() => {
    User.getProfile({userID: Cookies.get("_akada__user_")}).then(res => {
      if (res.type && res.type === "error") {
        toast.error(res.message);
        return navigate("/");
      }
      setProfile(res.profile);
      
    }).catch(err => {
      const error = err.response?.data || {message: "An error occured, check your network and retry."};
      console.log(error);
      toast.error('Access denied.');
      return navigate("/login");
    })
  }, [])

  return (
    <div className="dashboard">
      <div className="welcome">
        <h3 className="h3 tracking-tight">Welcome,</h3>
        <h2 className="h2 tracking-tight">{profile.fname}.</h2>
      </div>


      <div className="cta-cards">

          <button>
            <Link to={"/submit-review?type=Institution"}>
              <h4 className="h4">Make a one time review of your institution</h4>
              <img alt="Book Check" src={BookCheckSvg} />
            </Link>
          </button>

        <button>
          <Link to={"/submit-review?type=Course"}>
            <h4 className="h4">Review your courses and department</h4>
            <img src={RateSvg} alt="Rate" />
          </Link>
        </button>

      </div>

      <div className="text-centers p-5 h4 bg-indigo text-white rounded-lg my-2 md:w-[49%]">
        <Link to={"/website-review"} className="my-2 inline-block hover:text-cyan">Rate your experience on this website<ChartBarIcon width={"30px"} display={"inline"} /></Link>
        
      </div>
      
    </div>
  )
}

export default UsersDashboard
