import { DotsHorizontalIcon, EyeIcon, PencilIcon, TrashIcon } from "@heroicons/react/solid";
import { useRef, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import Transition from "./Transition"
import { toast } from "react-toastify";

const FeedbackMenu = ({id}) => {

    const [dropdownOpen, setDropdownOpen] = useState(false);
    
    // close on click outside
    useEffect(() => {
      const clickHandler = ({ target }) => {
        if (
          !dropdownOpen ||
          dropdown.current.contains(target) ||
          trigger.current.contains(target)
        )
          return
        setDropdownOpen(false)
      }
      document.addEventListener("click", clickHandler)
      return () => document.removeEventListener("click", clickHandler)
    })
  
    // close if the esc key is pressed
    useEffect(() => {
      const keyHandler = ({ keyCode }) => {
        if (!dropdownOpen || keyCode !== 27) return
        setDropdownOpen(false)
      }
      document.addEventListener("keydown", keyHandler)
      return () => document.removeEventListener("keydown", keyHandler)
    })
  
    const trigger = useRef(null)
    const dropdown = useRef(null)
    const openDeleteModal = () => {

    }

    
  const handleDelete = () => {
    toast.error("You cannot delete this submission.")
  }

  const handleEdit = () => {
    toast.error("You cannot edit this submission.")
  }

    return (
        <>
            <div className="relative inline-flex">
            <button
                ref={trigger}
                className="inline-flex z-10 justify-center items-center group"
                aria-haspopup="true"
                onClick={() => setDropdownOpen(!dropdownOpen)}
                aria-expanded={dropdownOpen}
            >
            <DotsHorizontalIcon className="h-5 cursor-pointer text-gray-500" onClick={() => setDropdownOpen(!dropdownOpen)} />
            </button>

            <Transition
                className="origin-top-right z-20 absolute top-full right-0 min-w-42 bg-white border border-sky-200 rounded shadow-lg overflow-hidden mt-1"
                show={dropdownOpen}
                enter="transition ease-out duration-200 transform"
                enterStart="opacity-0 -translate-y-2"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-out duration-200"
                leaveStart="opacity-100"
                leaveEnd="opacity-0"
            >
                <div
                ref={dropdown}
                onFocus={() => setDropdownOpen(true)}
                onBlur={() => setDropdownOpen(false)}
                className="w-full"
                >
                <div className="flex flex-col w-full ">
                    <Link
                    to={"/users/feedbacks/" + id}
                    className="flex gap-2 items-center justify-between px-2 py-1 bg-sky-50 border-b border-sky-400 text-sky-700 font-bold w-full"
                    >
                    View <EyeIcon className="h-4" />
                    </Link>
                    
                    <button
                    onClick={() => handleEdit()}
                    to={"/users/feedbacks/" + id + "/edit"}
                    className="flex gap-2 items-center justify-between bg-sky-50 border-b border-sky-400 px-2 py-1 text-sky-700 font-bold w-full"
                    >
                    Edit <PencilIcon className="h-4" />
                    </button>

                    <button
                    onClick={() => handleDelete()}
                    className="flex w-full gap-2 items-center justify-between bg-red-50 px-2 py-1 text-red-700 font-bold"
                    >
                    Delete <TrashIcon className="h-4" />
                    </button>
                </div>
                </div>
            </Transition>

            {/* <DeleteFeedback
                name={name}
                isOpen={isOpen}
                closeModal={closeDeleteModal}
            /> */}
            </div>
        </>
    )

}


export default FeedbackMenu;