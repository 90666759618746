import React from 'react'
import BottomNavLink from './BottomNavLink'

const BottomNav = () => {
  return (
    <div className='bottomnav'>
        <ul>
            <BottomNavLink text="Home" to="/users/dashboard" />
            <BottomNavLink text="Feedbacks" to="/users/feedbacks" />
            <BottomNavLink text="Settings" to="/users/settings" />
            <BottomNavLink text="Support" to="/contact" />
        </ul>
    </div>
  )
}

export default BottomNav