import React, { useState } from "react"

import "../Feedback/Feedback.scss"

import DropList from "../../../components/DropList"
import PrivacyNotification from "../../../components/PrivacyNotification"
import FeedbackDetails from "../Feedback/components/FeedbackDetails"

const listBoxData = [
  "Course Feedback",
  "Institution Feedback",
  "Department Feedback",
  "Faculty Feedback",
  "Lecturer Feedback",
]

const SubmitFeedback = () => {
  const [selected, setSelected] = useState("")
  return (
    <div className="feedback">
      <h1 className="h1">Submit Feedback</h1>

      <div className="select-feedback">
        <span className="text-white">Select feedback type</span>

        <DropList
          data={listBoxData}
          selected={selected}
          setSelected={setSelected}
        />
      </div>

      <PrivacyNotification />

      <FeedbackDetails />
    </div>
  )
}

export default SubmitFeedback
