import { FolderIcon } from "@heroicons/react/outline"

const AdminPageHeader = ({ title, description, stat }) => {
  return (
    <div className="dashboardheader">
      <div className="head">
        <h4 className="h4">{title}</h4>
        <small>
          {description.length > 100
            ? `${description.substring(0, 97)}...`
            : description}
        </small>
      </div>

      {stat && (
        <div className="stats">
          <div className="stat">
            <FolderIcon />

            <div>
              <h5 className="h5">{stat.value}</h5>
              <small>{stat.text}</small>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default AdminPageHeader
