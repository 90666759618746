import React from "react"
import { Outlet } from "react-router-dom"
import Footer from "../components/Footer/Footer"
// import Header from "../components/Header/Header"
import Navbar from "../components/Navbar"

const GeneralLayout = () => {
  return (
    <>
      {/* <Header /> */}
      <Navbar />

      <Outlet />

      <Footer />
    </>
  )
}

export default GeneralLayout
