import { useState } from "react"
import { useEffect } from "react"
import ReactApexChart from "react-apexcharts"

import "./HomeChart.scss"

const config = (horizontal = false) => {
  return {
    series: [
      {
        name: "Rate",
        data: [1400, 1430, 1448, 770, 540, 580, 690, 600, 800, 580],
      },
    ],
    options: {
      plotOptions: {
        bar: {
          horizontal,
          distributed: true,
          startingShape: "flat",
          endingShape: "rounded",
          dataLabels: {
            position: "bottom",
          },
          barHeight: horizontal ? "70" : '',
        },
      },
      xaxis: {
        categories: [
          "UI",
          "UNILAG",
          "FUTA",
          "UNILAG",
          "UNN",
          "UNIOSUN",
          "UNIABUJA",
          "BAYERO",
          "FUTMINNA",
          "LASU",
        ],
        labels: {
          style: {
            fontFamily: "Red Hat Display",
          },
        },
        group: {
          style: {
            fontFamily: "Red Hat Display"
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            fontFamily: "Red Hat Display",
          },
        },
      },
      dataLabels: {
        style: {
          fontFamily: "Red Hat Display",
        },
      },
      tooltip: {
        style: {
          fontFamily: "Red Hat Display",
        },
      },
    },
  }
}

const HomeChart = () => {
  const [configuration, setConfiguration] = useState(config())

  useEffect(() => {
    if (window.innerWidth > 850) return

    setConfiguration(config(true))
    return
  }, [])

  useEffect(() => {
    window.addEventListener("resize", (e) => {
      if (e.target.innerWidth > 850) {
        setConfiguration(config(false))

        return
      }

      setConfiguration(config(true))
      return
    })

    return
  }, [])

  return (
    <div className="flex gap-10 justify-around my-20 flex-wrap px-5 items-center">
      
      <div className="homechart  py-5 rounded-lg md:w-[45%]">
        <h5 className="h5 tracking-tighter">
          Employment rate in some Nigerian institutions
        </h5>
        <ReactApexChart
          options={configuration.options}
          series={configuration.series}
          height={configuration.options.plotOptions.bar.horizontal ? '400' : '400'}
          type="bar"
          key={configuration.options.plotOptions.bar.horizontal.toString()}
        />
      </div>
      
      <div className="homechart  py-5 rounded-lg md:w-[50%]">
        <h5 className="h5 tracking-tighter">
          Thoughts on "How challenging is studying Medicine and Surgery in Nigeria."
        </h5>
        <ReactApexChart
          type="pie"
          options={{
              series: [25, 30, 12, 9, 1],
              labels: [
                "Very Challenging",
                "Challenging",
                "Average",
                "Easy",
                "Very Easy"
              ],
          }}
          series={[25, 30, 12, 9, 1]}
        />
      </div>
        
    </div>
  )
}

export default HomeChart
