import { useEffect, useState } from "react"
import { Institution } from "../../../../api"


const FeedbackDetails = ({answers, feedback}) => {
  const [schools, setSchools] = useState([]);
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    Institution.getAll()
    .then(res => {
      console.log(res);
      setSchools(res.schools);
    })

    Institution.getAllDepartments()
    .then(res => {
      setDepartments(res.departments);
    })
  }, [])

  return (
    <div className="feedbackdetails">
      <h2 className="h4">{feedback.type}</h2>
      <p>Submitted: {feedback.date && new Date(feedback.date).toDateString() + ", " + new Date(feedback.date).toLocaleTimeString()} </p>
      {
        answers &&
        answers.map(response => {
          return (
            <div className="feedbackblock">
              <FeedbackHead text={response.question} />

              <div className="detail">
                {
                  response.type === "institution" ?
                  schools.find(sch => sch.id === parseInt(response.answer))?.name
                  :
                  response.type === "department" ?
                  departments.find(dept => dept.id === parseInt(response.answer))?.name
                  :
                  response.answer
                }
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

const FeedbackHead = ({ text, required = false }) => (
  <div>
    <span className="text-lg tracking-tight font-semibold text-black">
      {text}
    </span>
    {required && <span className="text-rose-500 text-lg">*</span>}
  </div>
)

export default FeedbackDetails
