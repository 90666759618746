import { LockClosedIcon } from '@heroicons/react/outline'

import './PrivacyNotification.scss'

const PrivacyNotification = () => {
  return (
    <span className="privacy-notf">
      <LockClosedIcon />
      <small>We take privacy issues seriously. You can be sure your personal data is securely protected.</small>
    </span>
  )
}

export default PrivacyNotification