import React from "react"

const TextArea = ({ config, handleChange, value, disabled = false }) => {
  return (
    <div className="flex flex-col gap-y-2 w-full">
      {config.heading && <h5 className="h5 mb-0  mt-0 font-semibold">{config.heading}</h5>}

      <div className="flex flex-col gap-y-1 w-full">
        <label className="text-sm text-gray-600">{config.label}</label>
        <textarea
          className="w-full h-60 p-4 bg-gray-50 rounded-lg py-2 px-4 text-base ring-1 ring-gray-200 tracking-tight"
          placeholder={config.placeholder}
          maxLength={config.maxLength}
          disabled={disabled}
          onChange={handleChange}
          value={value}
          required
        />
      </div>
    </div>
  )
}

export default TextArea
